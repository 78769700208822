import { BookingPackageRoom } from "@qite/tide-client/build/types";
import { compact, range } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { usePopper } from "react-popper";
import Icon from "../../../components/icon";
import { TideItemForHotel, TideItemForRoundtrip } from "../../../types";
import { buildClassName } from "../../../utils";
import AgeSelect from "./age-select";
import AmountInput from "./amount-input";
import RoomAccommodationGroup from "./room-accommodation-group";
import { useI18next } from "gatsby-plugin-react-i18next";

interface RoomPickerProps {
  panelActive?: boolean;
  rooms: Room[];
  packageRooms?: BookingPackageRoom[];
  product?: TideItemForHotel | TideItemForRoundtrip;
  showHint?: boolean;

  onPanelToggle?: (state: boolean) => void;
  onRoomsChange?: (value: Room[]) => void;
}

export interface Room {
  adults: number;
  children: number;
  childAges: number[];
  accommodationCode?: string;
  regimeCode?: string;
  isVisible: boolean;
}

const RoomPicker: React.FC<RoomPickerProps> = ({
  panelActive,
  rooms,
  packageRooms,
  product,
  showHint,
  onPanelToggle,
  onRoomsChange,
}) => {
  const { t } = useI18next();
  const mql = typeof window !== "undefined" ? window.matchMedia("(min-width: 1200px)") : undefined;

  const [panelRooms, setPanelRooms] = useState<Room[]>(rooms);
  const [isTouched, setIsTouched] = useState<boolean>(false);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [hintActive, setHintActive] = useState<boolean>(showHint ?? false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 20],
        },
      },
      {
        name: "preventOverflow",
        options: {
          padding: 40,
        },
      },
      { name: "arrow", options: { element: arrowElement } },
    ],
  });

  const handleDocumentClick: EventListener = (event) => {
    if (referenceElement && !referenceElement.contains(event.target as Node)) {
      if (onPanelToggle) onPanelToggle(false);

      setIsTouched(false);
      setPanelRooms(rooms);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  });

  const handleInputFocus: React.FocusEventHandler<HTMLInputElement> = () => {
    activateRoomPanel();
  };

  const activateRoomPanel = () => {
    if (onPanelToggle) {
      onPanelToggle(true);
    }
    setHintActive(false);
  };

  const handleSelectAccommodationClick = (
    roomIndex: number,
    accommodationCode: string,
    regimeCode: string
  ) => {
    const newRooms = panelRooms.map((room, i) =>
      i === roomIndex ? { ...room, accommodationCode, regimeCode } : room
    );

    setPanelRooms(newRooms);
    setIsTouched(true);
  };

  const handleCloseClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (onRoomsChange) onRoomsChange(panelRooms);
    if (onPanelToggle) onPanelToggle(false);

    setIsTouched(false);
  };

  const formatRooms = (rooms: Room[] = []) => {
    if (rooms.length === 0) {
      return undefined;
    }

    return rooms
      .map((room) =>
        compact([
          room.adults > 0 && room.adults === 1
            ? t("1_ADULT")
            : t("X_ADULTS", { numberOfAdults: room.adults }),
          room.children > 0 &&
            `${
              room.children === 1
                ? t("1_CHILD")
                : t("X_CHILDREN", { numberOfChildren: room.children })
            } (${t("X_YEAR", { age: room.childAges.join(", ") })})`,
        ]).join(", ")
      )
      .join(" + ");
  };

  const getSummaryLabel = (room: BookingPackageRoom) => {
    if (!room?.options) return "";
    const selectedOption = room.options.find((x) => x.isSelected);
    return `${selectedOption?.accommodationName}${
      selectedOption?.regimeName ? ", " + selectedOption.regimeName : ""
    }`;
  };

  const sizeChanged =
    rooms.length !== panelRooms.length ||
    rooms.reduce((x, y) => x + y.adults, 0) !== panelRooms.reduce((x, y) => x + y.adults, 0) ||
    rooms.reduce((x, y) => x + y.children, 0) !== panelRooms.reduce((x, y) => x + y.children, 0);

  return (
    <div className="form__group form__group--icon" ref={setReferenceElement}>
      <div className={buildClassName(["qsm-hint", hintActive && "qsm-hint--active"])}>
        <button
          className="qsm-hint__close-button"
          type="button"
          onClick={() => setHintActive(!hintActive)}
        ></button>
        <Icon name="qsm-hotel" />
        <h2 className="qsm-hint__heading">{t("CONFIGURE_ROOMS")}</h2>
        <p>{t("CONFIGURE_ROOMING_COMPANY")}</p>
        <button className="cta" type="button" onClick={() => activateRoomPanel()}>
          {t("CONFIGURE_ROOMS")}
        </button>
      </div>

      <div className="form__group-input">
        <label className="form__label">{t("WHO_YOU_TRAVELING_WITH")}</label>
        <Icon name="qsm-user" />
        <input
          type="text"
          className="form__input"
          placeholder={t("YOUR_COMPANY")}
          onFocus={handleInputFocus}
          readOnly
          value={formatRooms(rooms)}
          style={{
            backgroundImage:
              "url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==&quot;)",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "scroll",
            backgroundSize: "16px 18px",
            backgroundPosition: "98% 50%",
            cursor: "auto",
          }}
        />
      </div>
      <div
        className={buildClassName(["configure-rooms", panelActive && "configure-rooms--active"])}
        ref={setPopperElement}
        style={mql?.matches ? styles.popper : undefined}
        {...attributes.popper}
      >
        <div className="configure-rooms__frame">
          <div className="configure-rooms__header">
            <div className="configure-rooms__heading">{t("ROOMS")}</div>
            <div className="configure-rooms__actions">
              <AmountInput
                label={t("NUMBER_OF_ROOMS")}
                value={panelRooms.length}
                onChange={(value) => {
                  setPanelRooms(
                    range(0, value).map((roomIndex) =>
                      panelRooms[roomIndex]
                        ? { ...panelRooms[roomIndex], isVisible: false }
                        : { adults: 2, children: 0, childAges: [], isVisible: false }
                    )
                  );

                  setIsTouched(true);
                }}
              />
            </div>
          </div>

          <div className="configure-rooms__body">
            <div
              className={buildClassName([
                "configure-rooms__groups",
                product && packageRooms && "configure-rooms__groups--product",
              ])}
            >
              {panelRooms.map((room, roomIndex) => (
                <div
                  className={buildClassName([
                    "configure-rooms__group",
                    room.isVisible && "configure-rooms__group--active",
                  ])}
                  key={roomIndex}
                >
                  <div className="configure-rooms__group-header">
                    <h3 className="configure-rooms__group-heading">
                      {t("ROOM_X", { roomNumber: roomIndex + 1 })}
                    </h3>
                    <div className="configure-rooms__group-actions">
                      <AmountInput
                        label={t("ADULTS")}
                        value={room.adults}
                        min={1}
                        onChange={(value) => {
                          setPanelRooms(
                            panelRooms.map((room, i) =>
                              i === roomIndex ? { ...room, adults: value } : room
                            )
                          );

                          setIsTouched(true);
                        }}
                      />
                      <AmountInput
                        label={t("CHILDREN")}
                        value={room.children}
                        onChange={(value) => {
                          setPanelRooms(
                            panelRooms.map((room, i) =>
                              i === roomIndex
                                ? {
                                    ...room,
                                    children: value,
                                    childAges: range(0, value).map(
                                      (childIndex) => room.childAges[childIndex] ?? 0
                                    ),
                                  }
                                : room
                            )
                          );

                          setIsTouched(true);
                        }}
                      />
                    </div>
                    {packageRooms && (
                      <>
                        {sizeChanged && (
                          <div className="configure-rooms__summary-collapse">
                            <span className="configure-rooms__summary-text">
                              {t("OCCUPANCY_CHANGED_PLEASE_APPLY")}
                            </span>
                          </div>
                        )}
                        {!sizeChanged && (
                          <div className="configure-rooms__summary-collapse">
                            {!sizeChanged && (
                              <span className="configure-rooms__summary-text">
                                {getSummaryLabel(packageRooms[roomIndex])}
                              </span>
                            )}
                            {!room.isVisible && packageRooms[roomIndex] && (
                              <button
                                type="button"
                                onClick={() => {
                                  const newRooms = panelRooms.map((room, i) =>
                                    i === roomIndex ? { ...room, isVisible: true } : room
                                  );
                                  setPanelRooms(newRooms);
                                }}
                                className="configure-rooms__collapse-anchor"
                                title={t("CHANGE_ROOM_REGIME")}
                              >
                                {t("CHANGE_ROOM_REGIME")}
                                <Icon name="roomconfig-pencil"></Icon>
                              </button>
                            )}
                            {room.isVisible && (
                              <button
                                type="button"
                                onClick={() => {
                                  const newRooms = panelRooms.map((room, i) =>
                                    i === roomIndex ? { ...room, isVisible: false } : room
                                  );
                                  setPanelRooms(newRooms);
                                }}
                                className="configure-rooms__collapse-anchor configure-rooms__collapse-anchor--close"
                                title={t("SAVE_CHOICE")}
                              >
                                {t("SAVE_CHOICE")}
                                <Icon name="roomconfig-close"></Icon>
                              </button>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {panelRooms[roomIndex].children > 0 && (
                    <div className="configure-rooms__group-children">
                      <label className="room-line__ages-label">{t("AGE_BY_DEPARTURE_DATE")}</label>
                      <div className="dropdown-group">
                        {range(0, panelRooms[roomIndex].children).map((childIndex) => (
                          <AgeSelect
                            key={childIndex}
                            value={panelRooms[roomIndex].childAges[childIndex]}
                            onChange={(value) => {
                              setPanelRooms(
                                panelRooms.map((room, selectorRoomIndex) =>
                                  roomIndex === selectorRoomIndex
                                    ? {
                                        ...room,
                                        childAges: room.childAges
                                          .map((age, i) => (childIndex === i ? value : age))
                                          .sort((a, b) => b - a),
                                      }
                                    : room
                                )
                              );

                              setIsTouched(true);
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  {product && !sizeChanged && packageRooms && packageRooms[roomIndex] && (
                    <RoomAccommodationGroup
                      room={room}
                      roomIndex={roomIndex}
                      product={product}
                      packageRoom={packageRooms[roomIndex]}
                      onOptionChange={handleSelectAccommodationClick}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="configure-rooms__footer">
            <button
              type="button"
              className={buildClassName([
                "cta",
                !isTouched && "cta--secondary",
                isTouched && "cta--primary",
                "cta--add",
              ])}
              title={t("APPLY")}
              onClick={handleCloseClick}
            >
              <span>
                <Icon name="ui-check" />
                {t("APPLY")}
              </span>
            </button>
          </div>
        </div>
        {mql?.matches && (
          <div className="configure-rooms__arrow" ref={setArrowElement} style={styles.arrow} />
        )}
      </div>
    </div>
  );
};

export default RoomPicker;

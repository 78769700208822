import { BookingPackageRoom } from "@qite/tide-client/build/types";
import { groupBy } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import Loader from "../../../components/loader";
import { Maybe, TideItemForHotel, Image, TideItemForRoundtrip } from "../../../types";
import { getChildAccomodations } from "../../../utils";
import RoomAccommodationItem from "./room-accommodation-item";
import { Room } from "./room-picker";

interface RoomAccommodationGroupProps {
  room: Room;
  roomIndex: number;
  product: TideItemForHotel | TideItemForRoundtrip;
  packageRoom: BookingPackageRoom;
  onOptionChange: (roomIndex: number, accommodationCode: string, regimeCode: string) => void;
}

export interface RoomAccommodation {
  code: string;
  image?: Maybe<Image>;
  title: string;
  description: string;
  facilities: string[];
  isSelected?: boolean;
  regimes: RoomRegime[];
}

export interface RoomRegime {
  code: string;
  title: string;
  price: number;
  isSelected?: boolean;
}

const RoomAccommodationGroup: React.FC<RoomAccommodationGroupProps> = ({
  room,
  roomIndex,
  product,
  packageRoom,
  onOptionChange,
}) => {
  const [accommodations, setAccommodations] = useState<RoomAccommodation[]>([]);
  const [selectedPrice, setSelectedPrice] = useState<number>(0);

  useEffect(() => {
    const results: RoomAccommodation[] = [];
    const accommodationItems = getChildAccomodations(product);
    const accommodationGroups = groupBy(packageRoom.options, (x) => x.accommodationCode);
    const defaultOption = packageRoom.options.find((x) => x.isSelected);

    for (var group in accommodationGroups) {
      const accommodationItem = accommodationItems.find(
        (x) => x.content?.general?.productcode === group
      );
      const first = accommodationGroups[group][0];

      const result = {
        code: accommodationItem?.content?.general?.productcode ?? group,
        title: accommodationItem?.content?.general?.title ?? first.accommodationName,
        description: accommodationItem?.content?.general?.description ?? "",
        facilities: accommodationItem?.content?.description?.facilities?.split(/[;\r\n]+/) ?? [],
        image: accommodationItem?.content?.description?.picture ?? undefined,
        regimes: accommodationGroups[group]
          .map((x) => ({
            code: x.regimeCode,
            title: x.regimeName,
            price: x.price,
          }))
          .sort((a, b) => a.price - b.price),
      } as RoomAccommodation;

      if (
        (room.accommodationCode && group === room.accommodationCode) ||
        (!room.accommodationCode && group === defaultOption?.accommodationCode)
      ) {
        result.isSelected = true;

        const regime = result.regimes.find(
          (x) =>
            (room.regimeCode && x.code === room.regimeCode) ||
            (!room.regimeCode && x.code === defaultOption?.regimeCode)
        );

        if (regime) {
          regime.isSelected = true;
          setSelectedPrice(regime.price);
        }
      }

      results.push(result);
    }

    setAccommodations(results);
  }, [product, room, packageRoom]);

  const handleOptionChange = (accommodationCode: string, regimeCode: string) => {
    if (onOptionChange) onOptionChange(roomIndex, accommodationCode, regimeCode);
  };

  return (
    <div className="configure-rooms__group-body">
      {accommodations.map((accommodation) => (
        <RoomAccommodationItem
          key={accommodation.code}
          accommodation={accommodation}
          selectedPrice={selectedPrice}
          onOptionChange={handleOptionChange}
        />
      )) ?? <Loader />}
    </div>
  );
};

export default RoomAccommodationGroup;

import React, { useState } from "react";
import { useEffect } from "react";
import Icon from "../../../components/icon";
import TypeaheadOptions from "../../../components/qsm/typeahead-options";
import { LookupData } from "../../../components/lookup-context/types";
import { filter } from "../../../components/lookup-context/utils";
import { usePopper } from "react-popper";
import { buildClassName } from "../../../utils";
import { useI18next } from "gatsby-plugin-react-i18next";

interface LocationPickerProps {
  panelActive?: boolean;
  value?: string;
  lookupData?: LookupData[];
  onPanelToggle?: (state: boolean) => void;
  onFocus?: () => void;
  onSelect?: (id: string) => void;
}

const LocationPicker: React.FC<LocationPickerProps> = ({
  panelActive,
  lookupData = [],
  value,
  onPanelToggle,
  onFocus,
  onSelect,
}) => {
  const { t } = useI18next();
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  const handleDocumentClick: EventListener = (event) => {
    if (referenceElement && !referenceElement.contains(event.target as Node)) {
      if (onPanelToggle) onPanelToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => document.removeEventListener("mousedown", handleDocumentClick);
  });

  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    if (value) {
      setSearchTerm(value);
    }
  }, [value]);

  const handleSearchTermChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchTerm(e.target.value);

    if (onPanelToggle) {
      onPanelToggle(e.target.value.length > 2);
    }
  };

  const handleSelect = (id: string) => {
    const name = lookupData.find((x) => x.id === id)?.name;

    if (name) {
      setSearchTerm(name);
    }

    if (onSelect) {
      onSelect(id);
    }
  };

  const results = filter(searchTerm, lookupData);
  const hasResults = searchTerm.length > 2 && results.length > 0;

  return (
    <div className="form__group form__group--icon" ref={setReferenceElement}>
      <div className="form__group-input">
        <div className="typeahead typeahead--qsm-inline">
          <label className="form__label">{t("WHERE_ARE_YOU_GOING")}</label>
          <div className="typeahead__trigger typeahead__trigger--icon">
            <Icon name="qsm-location" />
            <input
              type="text"
              className="form__input"
              placeholder={t("ENTER_DESTINATION_OR_HOTEL")}
              value={searchTerm}
              onFocus={onFocus}
              onChange={handleSearchTermChange}
              required
            />
          </div>
          <div
            className={buildClassName([
              "qsm__panel",
              hasResults && panelActive && "qsm__panel--active",
            ])}
            ref={setPopperElement}
            style={{ ...styles.popper, width: "100%" }}
            {...attributes.popper}
          >
            <TypeaheadOptions searchTerm={searchTerm} results={results} onSelect={handleSelect} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationPicker;

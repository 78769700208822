import { omit } from "lodash";
import React, { useEffect, useState } from "react";
import { setBookingRooms, setDateRange, setProductId, setRooms } from "../qsm-store/reducer";
import { sortProps } from "./utils";

import JsonURL from "@jsonurl/jsonurl";
import { buildTideClientConfig } from "@qite/tide-booking-component/src/booking-wizard/utils/tide-api-utils";
import { book, getPrintActions, print, update } from "@qite/tide-client";
import {
  AgentPrintAction,
  AgentPrintActionRequest,
  BookingPackage,
  BookingPackageBookRequest,
  BookingPackageDossier,
  BookingPackageRequest,
  BookingPackageRoom,
  BookingPackageUpdateRequest,
  PackageRoomOption,
} from "@qite/tide-client/build/types";
import { format, formatISO } from "date-fns";
import { graphql } from "gatsby";
import Icon from "../../../components/icon";
import Loader from "../../../components/loader";
import { fetchLookupData } from "../../../components/lookup-context/mapping";
import { LookupData } from "../../../components/lookup-context/types";
import { officeId } from "../../../tide-api";
import { compileRequest, handleAnchorClick } from "../../../utils";
import { useMemberStore } from "../../member-store/context";
import { useProductStore } from "../product-store/context";
import {
  setEstimatedPrice,
  setHasFlight,
  setHasTransfer,
  setIsUnavailable,
  setPrice,
} from "../product-store/reducer";
import { useQSMStore } from "../qsm-store/context";
import { getPackageDetails } from "../utils/search-util";
import DatePicker, { DateRange } from "./date-picker";
import LocationPicker from "./location-picker";
import RoomPicker, { Room } from "./room-picker";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

interface QSMProps {
  productSpecific?: boolean;
  searchPagePath?: string;
  onRoomOptionsReceived?: (roomOptioms: PackageRoomOption[]) => void;
  onDestinationChange?: (id: number | undefined, destinationType?: "country" | "region") => void;
  reload?: () => void;
}

interface PrintButtonState {
  isLoading: boolean;
  label: string;
}

const QSM: React.FC<QSMProps> = (props) => {
  const { t, navigate, language } = useI18next();

  const [qsmState, qsmDispatch] = useQSMStore();
  const [productState, productDispatch] = useProductStore();
  const [memberState] = useMemberStore();

  const [lookupData, setLookupData] = useState<LookupData[]>([]);
  const [locationPanelActive, setLocationPanelActive] = useState<boolean>(false);
  const [roomPanelActive, setRoomPanelActive] = useState<boolean>(false);
  const [datePanelActive, setDatePanelActive] = useState<boolean>(false);
  const [previousRequest, setPreviousRequest] = useState<string>("");

  const [isBookable, setBookable] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [offer, setOffer] = useState<BookingPackageDossier>();
  const [printActions, setPrintActions] = useState<AgentPrintAction[]>();
  const [bookingPackage, setBookingPackage] = useState<BookingPackage>();
  const [printButtonState, setPrintButtonState] = useState<PrintButtonState>({
    isLoading: false,
    label: t("PRINT_OFFER"),
  });

  const selectedItem = lookupData.find(
    (x) => x.id === qsmState?.product?.id || x.id === qsmState?.productId
  );

  const fetchPackage = async () => {
    if (
      qsmState?.product &&
      qsmState?.dateRange?.fromDate &&
      qsmState?.dateRange?.toDate &&
      qsmState?.rooms
    ) {
      try {
        var currentRequest = compileRequest(
          qsmState.product,
          qsmState.dateRange.fromDate,
          qsmState.dateRange.toDate,
          qsmState.rooms
        );

        if (!qsmState?.loaded) return;
        if (currentRequest == previousRequest) return;
        setPreviousRequest(currentRequest);

        // Fetch package
        setIsLoading(true);
        if (productDispatch) {
          productDispatch(setIsUnavailable(false));
          productDispatch(setPrice(undefined));
          productDispatch(setEstimatedPrice(undefined));
        }
        const response = await getPackageDetails(
          qsmState?.rooms,
          formatISO(qsmState?.dateRange?.fromDate, { representation: "date" }) + "T00:00:00Z",
          formatISO(qsmState?.dateRange?.toDate, { representation: "date" }) + "T00:00:00Z",
          qsmState?.product?.content?.general?.product?.code,
          undefined,
          language
        );
        setIsLoading(false);

        if (!response.errorCode && response.payload) {
          const bookingPackage = response.payload;
          const activeOption =
            bookingPackage.options.find((x) => x.isSelected) ?? bookingPackage.options[0];

          if (activeOption) {
            if (productDispatch) {
              productDispatch(setPrice({ defaultPrice: activeOption.price }));
              productDispatch(setEstimatedPrice(undefined));

              if (activeOption.includedServiceTypes.some((x) => x === 7)) {
                productDispatch(setHasFlight(true));
              }

              if (activeOption.includedServiceTypes.some((x) => x === 13)) {
                productDispatch(setHasTransfer(true));
              }
            }

            if (qsmDispatch) {
              qsmDispatch(setBookingRooms(activeOption.rooms));
            }

            updateRoomsInPackage(qsmState.rooms);

            // Product is bookable
            setBookable(true);
            setBookingPackage(bookingPackage);
          } else {
            if (productDispatch) {
              productDispatch(setIsUnavailable(true));
              productDispatch(setPrice(undefined));

              productDispatch(
                setEstimatedPrice({
                  defaultPrice: qsmState.product?.content?.general?.estimatedPrice ?? undefined,
                  promoPrice: qsmState.product?.content?.general?.estimatedPromoPrice ?? undefined,
                })
              );
            }

            if (qsmDispatch) {
              qsmDispatch(setBookingRooms([]));
            }

            setBookable(false);
          }
        } else {
          if (productDispatch) {
            productDispatch(setIsUnavailable(true));
            productDispatch(setPrice(undefined));

            productDispatch(
              setEstimatedPrice({
                defaultPrice: qsmState.product?.content?.general?.estimatedPrice ?? undefined,
                promoPrice: qsmState.product?.content?.general?.estimatedPromoPrice ?? undefined,
              })
            );
          }

          if (qsmDispatch) {
            qsmDispatch(setBookingRooms([]));
          }

          setBookable(false);
        }
      } catch {
        if (productDispatch) {
        }

        // Something went wrong
        setBookable(false);
      }
    }
  };

  const asyncFetchLookupData = async () => {
    const data = await fetchLookupData(language);
    setLookupData(data);
  };

  const fetchPrintActions = async () => {
    if (!memberState?.member) return;
    const config = buildTideClientConfig();
    const printActions = await getPrintActions(config, 0);
    const validPrintActions = printActions.filter(
      (x) => !x.validEntryStatuses.length || x.validEntryStatuses.some((y) => y == 0)
    );
    setPrintActions(validPrintActions);
  };

  useEffect(() => {
    fetchPackage();
  }, [
    qsmState?.product,
    qsmState?.dateRange?.fromDate?.valueOf(),
    qsmState?.dateRange?.toDate?.valueOf(),
    qsmState?.loaded,
    previousRequest,
    JSON.stringify(qsmState?.rooms?.map(sortProps)),
  ]);

  useEffect(() => {
    asyncFetchLookupData();
  }, []);

  useEffect(() => {
    fetchPrintActions();
  }, []);

  const handleLocationSelect = (id: string) => {
    setLocationPanelActive(false);

    if (qsmDispatch) {
      qsmDispatch(setProductId(id));
    }

    if (props.onDestinationChange) {
      const selectedLookupItem = lookupData.find((x) => x.id === id);

      if (selectedLookupItem?.templateName === "Country") {
        props.onDestinationChange(selectedLookupItem.itemId, "country");
      } else if (selectedLookupItem?.templateName === "Region") {
        props.onDestinationChange(selectedLookupItem.itemId, "region");
      }
    }
  };

  const handleSearchClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    const params: Record<string, string> = {};

    if (selectedItem?.templateName === "Country") {
      params["country"] = `${selectedItem.itemId}`;
    } else if (selectedItem?.templateName === "Region") {
      params["region"] = `${selectedItem.itemId}`;
    } else if (qsmState?.countryId) {
      params["country"] = `${qsmState.countryId}`;
    } else if (qsmState?.regionId) {
      params["region"] = `${qsmState.regionId}`;
    }

    if (qsmState?.rooms) {
      const serialized = JsonURL.stringify(
        qsmState.rooms.map((room) => omit(room, ["children"])),
        { AQF: true }
      );

      if (serialized) {
        params["rooms"] = serialized;
      }
    }

    if (qsmState?.dateRange?.fromDate) {
      params["from"] = format(qsmState.dateRange.fromDate, "yyyyMMdd");
    }

    if (qsmState?.dateRange?.toDate) {
      params["to"] = format(qsmState.dateRange.toDate, "yyyyMMdd");
    }

    const path =
      selectedItem?.templateName === "Hotel" || selectedItem?.templateName === "Roundtrip"
        ? selectedItem.url
        : `/${props.searchPagePath}`;

    const paramString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");

    // window.location.href = `${path}?${paramString}`;
    navigate(`${path}?${paramString}`);
    if (props.reload) props.reload(); // Force to reload search results, because navigate will only change the url, but not trigger a page reload.
  };

  const handleBookClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    const params: Record<string, string> = {};

    if (qsmState?.rooms) {
      const serialized = JsonURL.stringify(
        qsmState.rooms.map((room) => omit(room, ["children"])),
        { AQF: true }
      );

      if (serialized) {
        params["rooms"] = serialized;
      }
    }

    if (qsmState?.dateRange?.fromDate) {
      params["startDate"] = format(qsmState.dateRange.fromDate, "yyyy-MM-dd");
    }

    if (qsmState?.dateRange?.toDate) {
      params["endDate"] = format(qsmState.dateRange.toDate, "yyyy-MM-dd");
    }

    params["catalog"] = "1";

    const path = window.location.pathname;

    const paramString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");

    window.location.href = `${path}${path.endsWith("/") ? "" : "/"}boeken?${paramString}`;
  };

  const createOffer = async (): Promise<BookingPackageDossier> => {
    if (!bookingPackage || !qsmState || !memberState?.member || !printActions) {
      throw new Error("Invalid input");
    }

    const { member } = memberState;
    const config = buildTideClientConfig();
    const selectedOption =
      bookingPackage.options.find((x) => x.isSelected) ?? bookingPackage.options[0];
    const pax = selectedOption.requestRooms.flatMap((room) => room.pax);

    let newOffer: BookingPackageDossier;
    // if (offer) {
    //   const request: BookingPackageRequest<BookingPackageUpdateRequest> = {
    //     officeId,
    //     agentId: member.agentId,
    //     payload: {
    //       dossierNumber: offer.number,
    //       package: bookingPackage,
    //       status: 0,
    //       pax,
    //       nonTravelPax: [],
    //       notifications: [],
    //       customerRequests: [],
    //       // tagIds: [9]
    //     },
    //   };

    //   newOffer = await update(config, request);
    // } else {
    const request: BookingPackageRequest<BookingPackageBookRequest> = {
      officeId,
      agentId: member.agentId,
      payload: {
        package: bookingPackage,
        status: 0,
        pax,
        nonTravelPax: [],
        notifications: [],
        customerRequests: [],
        // tagIds: [9]
      },
    };

    newOffer = await book(config, request);
    // }

    setOffer(newOffer);
    return newOffer;
  };

  const handlePrintOfferClick = async () => {
    // Build offer
    setPrintButtonState({ isLoading: true, label: t("GENERATE_OFFER") });
    const _offer = await createOffer();

    // Print offer
    if (!_offer) throw new Error("An offer is required");
    setPrintButtonState({ isLoading: true, label: t("GENERATE_PRINT") });
    await printOffer(_offer);

    // Done
    setPrintButtonState({ isLoading: false, label: t("PRINT_OFFER") });
  };

  const printOffer = async (_offer: BookingPackageDossier) => {
    if (!_offer) throw new Error("An offer is required");
    if (!printActions?.length) throw new Error("A print action is required.");

    const config = buildTideClientConfig();
    const printActionId = printActions[0].id;
    const request: AgentPrintActionRequest = { id: printActionId, dossierNumber: _offer.number };

    const response = await print(config, request).catch();
    const arrayBuffer = await response.arrayBuffer();
    const blob = new Blob([arrayBuffer], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  };

  const updateHistory = (rooms?: Room[], dateRange?: DateRange) => {
    const params: Record<string, string> = {};
    const newRooms = rooms ?? qsmState?.rooms;
    const newFromDate = dateRange?.fromDate ?? qsmState?.dateRange.fromDate;
    const newToDate = dateRange?.toDate ?? qsmState?.dateRange.toDate;

    if (newRooms) {
      const serialized = JsonURL.stringify(
        newRooms.map((room) => omit(room, ["children"])),
        { AQF: true }
      );

      if (serialized) {
        params["rooms"] = serialized;
      }
    }

    if (newFromDate) {
      params["from"] = format(newFromDate, "yyyyMMdd");
    }

    if (newToDate) {
      params["to"] = format(newToDate, "yyyyMMdd");
    }

    const path = window.location.pathname;

    const paramString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");

    window.history.replaceState("", "", `${path}?${paramString}`);
  };

  const updateRoomsInPackage = (rooms: Room[], updatePrice?: boolean) => {
    var packageRooms = qsmState?.bookingRooms;
    var price = productState?.price;

    if (!packageRooms || rooms.length !== packageRooms.length) return;

    var currentPrice = calculateRoomPrice(packageRooms);
    var updatedPackageRooms = packageRooms.map((room, index) => ({
      ...room,
      options: room.options.map((option) => ({
        ...option,
        isSelected: rooms[index].accommodationCode
          ? option.accommodationCode === rooms[index].accommodationCode &&
            option.regimeCode === rooms[index].regimeCode
          : option.isSelected,
      })),
    }));
    var newPrice = calculateRoomPrice(updatedPackageRooms);

    const updatedPrice = {
      ...price,
      defaultPrice: (price?.defaultPrice ?? 0) - currentPrice + newPrice,
    };

    if (productDispatch && updatePrice) productDispatch(setPrice(updatedPrice));
    if (qsmDispatch) qsmDispatch(setBookingRooms(updatedPackageRooms));
  };

  const calculateRoomPrice = (rooms?: BookingPackageRoom[]) => {
    let price = 0;
    if (rooms) {
      rooms.forEach((x) => {
        var selected = x.options.find((y) => y.isSelected);
        price += selected?.price ?? 0;
      });
    }
    return price;
  };

  const locationValue = lookupData.find((x) =>
    qsmState?.productId
      ? x.id === qsmState?.productId &&
        (x.templateName === "Roundtrip" || x.templateName === "Hotel")
      : qsmState?.countryId
      ? x.itemId && x.itemId === qsmState?.countryId && x.templateName === "Country"
      : x.itemId && x.itemId === qsmState?.regionId && x.templateName === "Region"
  );

  return qsmState && qsmState.loaded ? (
    <div className="qsm qsm--inline">
      <div className="form__region">
        <div className="form__row">
          {!props.productSpecific && (
            <LocationPicker
              lookupData={lookupData}
              panelActive={locationPanelActive}
              onFocus={() => setLocationPanelActive(true)}
              value={locationValue?.name}
              onPanelToggle={(isActive) => {
                if (isActive) {
                  setRoomPanelActive(false);
                  setDatePanelActive(false);
                }
                setLocationPanelActive(isActive);
              }}
              onSelect={handleLocationSelect}
            />
          )}
          <RoomPicker
            panelActive={roomPanelActive}
            showHint={props.productSpecific}
            onPanelToggle={(isActive) => {
              if (!isLoading) {
                if (isActive) {
                  setLocationPanelActive(false);
                  setDatePanelActive(false);
                }
                setRoomPanelActive(isActive);
              }
            }}
            rooms={qsmState.rooms}
            onRoomsChange={(value) => {
              updateRoomsInPackage(value, true);
              updateHistory(value, undefined);

              if (qsmDispatch) qsmDispatch(setRooms(value));
            }}
            product={qsmState.product}
            packageRooms={qsmState.bookingRooms}
          />
          <DatePicker
            value={qsmState.dateRange}
            panelActive={datePanelActive}
            duration={qsmState.duration}
            onPanelToggle={(isActive) => {
              if (!isLoading) {
                if (isActive) {
                  setLocationPanelActive(false);
                  setRoomPanelActive(false);
                }
                setDatePanelActive(isActive);
              }
            }}
            onChange={(value) => {
              setDateRange(value);
              updateHistory(undefined, value);

              if (qsmDispatch) qsmDispatch(setDateRange(value));
              window.scrollTo(0, 0);
            }}
          />
          <div className="form__group form__group--submit">
            {!props.productSpecific && (
              <button type="button" className="cta" onClick={handleSearchClick}>
                <Icon name="ui-search" />
                <Trans>SEARCH</Trans>
              </button>
            )}
            {props.productSpecific &&
              !isLoading &&
              (isBookable ? (
                <button type="button" className="cta" onClick={handleBookClick}>
                  <Trans>BOOK_NOW</Trans>
                </button>
              ) : (
                <a href="#offer-form" className="cta" onClick={handleAnchorClick}>
                  <Trans>REQUEST_OFFER</Trans>
                </a>
              ))}
          </div>
          {props.productSpecific &&
            !isLoading &&
            isBookable &&
            printActions &&
            language == "nl-BE" && // Temporary until prints are multilangual.
            memberState?.member &&
            printActions &&
            printActions.length > 0 && (
              <div className="form__group">
                <button
                  type="button"
                  className="spinner-button cta"
                  onClick={handlePrintOfferClick}
                  disabled={printButtonState.isLoading}
                >
                  (printButtonState.isLoading ? <Loader></Loader> : '') (printButtonState.label)
                </button>
              </div>
            )}
        </div>
      </div>
    </div>
  ) : null;
};

export default QSM;

export const query = graphql`
  fragment QSMFragment on Query {
    allTideItem(
      filter: { templateName: { in: ["Hotel", "Roundtrip"] }, language: { eq: $language } }
    ) {
      nodes {
        ...QSMProductFragment
      }
    }
  }

  fragment QSMProductFragment on TideItem {
    id
    name
    templateName
    ... on TideItemForHotel {
      content {
        general {
          title
          slug
        }
      }
    }
    ... on TideItemForRoundtrip {
      content {
        general {
          title
          slug
          combinationTrip
        }
      }
    }
    parentItem {
      id
      name
      templateName
      ... on TideItemForCountry {
        content {
          general {
            title
          }
        }
      }
      ... on TideItemForRegion {
        content {
          general {
            title
          }
        }
      }
      parentItem {
        id
        name
        templateName
        ... on TideItemForGeographicalRegion {
          content {
            general {
              title
            }
          }
        }
      }
    }
  }
`;

import { RoomAccommodation } from "./room-accommodation-group";
import React, { useEffect, useState } from "react";
import { buildClassName, setImageParameters } from "../../../utils";
import { useI18next } from "gatsby-plugin-react-i18next";

interface RoomAccommodationItemProps {
  accommodation: RoomAccommodation;
  selectedPrice: number;
  onOptionChange: (accommodationCode: string, regimeCode: string) => void;
}

const RoomAccommodationItem: React.FC<RoomAccommodationItemProps> = ({
  accommodation,
  selectedPrice,
  onOptionChange,
}) => {
  const { t } = useI18next();
  const [regimeCode, setRegimeCode] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const placeholderImage =
    "https://tide-media.azurewebsites.net/media/10/Products/Placeholders/placeholder_accommodation-card-v2-1.webp";

  useEffect(() => {
    const regime = accommodation.regimes.find((x) => x.isSelected);
    if (regime) {
      setRegimeCode(regime.code);
      setPrice(regime.price);
    } else {
      setRegimeCode(accommodation.regimes[0].code);
      setPrice(accommodation.regimes[0].price);
    }
  }, [accommodation.regimes]);

  const getPriceDelta = () => {
    var twoDigits = Math.round((price - selectedPrice) * 100) / 100;
    return twoDigits > 0 ? `+ € ${twoDigits}` : `- € ${twoDigits}`;
  };

  const handleSelectAccommodationClick = () => {
    if (onOptionChange) onOptionChange(accommodation.code, regimeCode);
  };

  const handleRegimeChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    var regime = accommodation.regimes.find((x) => x.code == event.target.value);
    setRegimeCode(regime?.code ?? "");
    setPrice(regime?.price ?? 0);

    if (accommodation.isSelected) {
      if (onOptionChange) onOptionChange(accommodation.code, regime?.code ?? "");
    }
  };

  return (
    <>
      <div
        key={accommodation.code}
        className={buildClassName([
          "productcard",
          "productcard--accommodation",
          accommodation.isSelected && " productcard--selected",
        ])}
      >
        <div className="productcard__header">
          <div className="image">
            <img
              src={setImageParameters(accommodation?.image?.url ?? placeholderImage, {
                width: 160,
                height: 160,
                mode: "crop",
              })}
              alt="Travelworld"
              className="image__content"
            />
          </div>
        </div>
        <div className="productcard__body">
          <h4 className="productcard__heading">{accommodation.title}</h4>
          <p className="productcard__description">{accommodation.description}</p>
          <ul className="list list--usps">
            {accommodation.facilities.map((facility, i) => (
              <li className="list__item" key={`facility-${i}-${facility}`}>
                {facility}
              </li>
            ))}
          </ul>
          <div className="productcard__configure">
            <div className="productcard__regime">
              <div className="select-wrapper">
                <div className="select-wrapper__select">
                  <select
                    disabled={accommodation.regimes.length == 1}
                    onChange={handleRegimeChange}
                    value={regimeCode}
                  >
                    {accommodation.regimes.map((regime) => (
                      <option key={regime.code} value={regime.code}>
                        {regime.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="productcard__select">
              <button
                type="button"
                className="cta cta--secondary"
                disabled={accommodation.isSelected}
                onClick={handleSelectAccommodationClick}
              >
                {accommodation.isSelected ? t("SELECTED") : t("SELECT")}
              </button>
            </div>
          </div>
          {selectedPrice != price && (
            <div className="productcard__summary">
              <div className="pricing">
                <div className="pricing__price">{getPriceDelta()}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RoomAccommodationItem;
